import React from "react";
import styled from "styled-components";
import ActionCreator from "../ActionCreator";
import Selectors from "../Selectors";
import { connect } from "react-redux";
import { withPage } from "../PageContainer";
import { Color, FontSize } from "../Components/Widget";
import { navbarHeight } from "../Components/Navbar";

/**
 * 網站 HELP 頁面
 * @return {HelpPage}
 */
function HelpPage(props) {
  return (
    <Wrapper>
      <HelpWrapper>
        <h2>FAQ</h2>
        <p>
          網頁內容正在建置中
          <br />
          近期會開放...
        </p>
      </HelpWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #eff1f7;
  min-height: calc(100vh - ${navbarHeight}px);

  & > .content {
    max-width: 1128px;
    margin: 0 auto;
    padding: 20px 0;

    & > .container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }
`;

const HelpWrapper = styled.div`
  margin-top: 26px;
  margin-left: 156px;
  h2 {
    margin: unset;
    margin-bottom: 24px;
    font-size: ${FontSize.largeTitle}px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33em;
    letter-spacing: normal;
    color: ${Color.Black_85};
  }
  p {
    margin: unset;
    font-size: ${FontSize.subTitle}px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67em;
    letter-spacing: 0.32px;
    color: ${Color.BlackBlack_1};
  }
`;

export default withPage(
  connect(
    (state, ownProps) => ({
      profile: Selectors.getLoginUser(state),
    }),
    ActionCreator
  )(HelpPage)
);
